* {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

article,
section {
  display: flow-root;
}
