@import '../../tokens.scss';

.Footer {
  padding: 0 $box-padding $box-padding;
  text-align: center;

  a.active {
    color: inherit;
  }

  &-icons {
    display: flex;
    flex-wrap: wrap;
    gap: $icon-space;
    justify-content: center;
    margin-bottom: $icon-space;

    .Icon {
      box-shadow: $shadow;
      transition: color $focus-time, background-color $focus-time, box-shadow $focus-time;

      &:focus,
      &:hover {
        box-shadow: $focus-shadow;
      }
    }
  }

  &-copyright {
    font-size: $small-font-size;
    line-height: $small-line-height;
  }
}
