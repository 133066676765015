@import '../../tokens.scss';
$base-class: 'Ticker';

.#{$base-class} {
  background: $section-bg-color;
  border: $line-thickness solid $line-color;
  border-radius: $box-radius;
  margin: $section-space auto;
  max-width: $max-width;
  opacity: 1;
  padding: $box-padding;
  transition: opacity $fade-time;
  width: calc(100% - ($box-padding * 2));

  &-row {
    display: flex;

    ul {
      list-style: none;
      margin: 0 0 0 $cell-padding;
      padding: 0;
    }

    li {
      display: inline;
    }
  }
}
