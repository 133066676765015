@import '../../tokens.scss';

.Icon {
  align-items: center;
  border-radius: 50%;
  color: $text-color;
  display: flex;
  font-size: 32px;
  height: 1.5em;
  justify-content: center;
  line-height: 1;
  transition: color $focus-time, background-color $focus-time;
  width: 1.5em;

  &--blue {
    background-color: $blue-bg-color;
  }

  &--brown {
    background-color: $brown-bg-color;
  }

  &--gray {
    background-color: $gray-bg-color;
  }

  &--green {
    background-color: $green-bg-color;
  }

  &--purple {
    background-color: $purple-bg-color;
  }

  &--red {
    background-color: $red-bg-color;
  }

  &--amazon {
    background-color: #146eb4;
  }

  &--bluesky {
    background-color: #1185fe;
  }

  &--codepen {
    background-color: #000000;
  }

  &--facebook {
    background-color: #3b5998;
  }

  &--github {
    background-color: #6e5494;
  }

  &--goodreads {
    background-color: #553b08;
  }

  &--instagram {
    background-color: #8a3ab9;
  }

  &--linkedin {
    background-color: #007bb5;
  }

  &--mastodon {
    background-color: #6364ff;
  }

  &--medium {
    background-color: #000000;
  }

  &--patreon {
    background-color: #f96854;
  }

  &--quora {
    background-color: #b92b27;
  }

  &--reddit {
    background-color: #ff5700;
  }

  &--substack {
    background-color: $text-color;
    background-image: url('https://ik.imagekit.io/cliff/icons/substack');
    background-size: cover;

    &:focus,
    &:hover {
      background-color: $link-focus-color;
    }
  }

  &--threads {
    background-color: #000000;
  }

  &--tumblr {
    background-color: #2c4762;
  }

  &--twitter {
    background-color: #55acee;
  }

  &--wattpad {
    background-color: $text-color;
    background-image: url('https://ik.imagekit.io/cliff/icons/wattpad');
    background-size: cover;

    &:focus,
    &:hover {
      background-color: $link-focus-color;
    }
  }

  &--xtwitter {
    background-color: #000000;
  }

  &--youtube {
    background-color: #bb0000;
  }
}
