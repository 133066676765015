@import '../../tokens.scss';

.Lexic {
  &-query {
    display: block;
    margin: $section-space auto;
    width: calc(100% - $box-padding*4);
  }

  &-results {
    background: $section-bg-color;
    border-radius: $box-radius;
    border: $line-thickness solid $line-color;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: $section-space $box-padding;
    opacity: 1;
    padding: $box-padding;
  }

  &-item {
    border-bottom: $line-thickness solid $line-color;
    line-height: $small-line-height;

    &:last-child {
      border-bottom: 0;
    }

    ol {
      display: grid;
      grid-auto-columns: 1fr;
      grid-auto-flow: column;
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  &-cell {
    border-right: $line-thickness solid $line-color;
    overflow-x: auto;
    padding: $box-padding;

    &:first-child {
      text-align: right;
    }

    &:last-child {
      border-right: 0;
    }
  }
}
