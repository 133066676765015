@import '../../tokens.scss';

.Image {
  &--loading {
    animation: $loading-animation;
  }

  &--hidden {
    display: none;
  }
}
