@import '../../tokens.scss';
$base-class: 'Heading';

.#{$base-class} {
  &:hover {
    .#{$base-class}-anchor {
      opacity: 1;
    }
  }

  &-anchor {
    font-size: $small-font-size;
    opacity: 0;
    position: relative;
    top: 0.05em;
    transition: opacity $fade-time;

    &:focus,
    &:hover {
      opacity: 1;
    }
  }
}
