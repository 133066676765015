@import './tokens.scss';

// Keep base styles unscoped for lower specificity.
body {
  background-color: $bg-color;
  color: $text-color;
  font-family: $normal-font;
  font-size: $normal-font-size;
  line-height: $normal-line-height;
}

h1 {
  font-size: 2.2em;
}

h2 {
  font-size: 1.6em;
}

h3 {
  font-size: 1.4em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1.1em;
}

h6 {
  font-size: 1em;
}

strong {
  color: $special-color;
}

small {
  font-size: $small-font-size;
}

big {
  font-size: $big-font-size;
  line-height: $big-line-height;
}

code {
  font-family: $code-font;
}

a {
  color: $link-color;
  text-decoration: none;
  transition: color $focus-time;

  &:focus,
  &:hover {
    color: $link-focus-color;
  }

  &.active {
    pointer-events: none;
  }
}

button {
  cursor: pointer;
}

hr {
  border: 0;
  border-top: $line-thickness solid $line-color;
  clear: both;
  margin: $section-space 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  &-main {
    flex-grow: 1;
  }
}

.Text {
  &--hidden {
    position: absolute;
    left:-9999px;
  }

  &--asl {
    font-family: $asl-font;
  }
}
