@import '../../tokens.scss';

.CardList {
  margin: $section-space $box-padding;

  > ul {
    display: flex;
    flex-wrap: wrap;
    gap: $card-space;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;

    > * {
      animation: $delayed-fade-in-animation;
      width: 14.5em;
    }
  }
}
