@import '../../tokens.scss';

.ImageSearch {
  &-query {
    display: block;
    margin: $section-space auto;
    width: 90%;
  }

  &-results {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  &-card {
    align-items: center;
    background-color: $purple-bg-color;
    display: flex;
    margin: $section-space $box-padding;
    padding: $box-padding;
  }
}
