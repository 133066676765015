@import '../../tokens.scss';
$base-class: 'NavWheel';
$yin-color: #000000;
$yang-color: #ffffff;
$horse-color: hsl(30, 50%, 60%);
$dove-color: hsl(55, 60%, 75%);
$turtle-color: hsl(130, 40%, 50%);
$owl-color: hsl(185, 60%, 70%);
$wolf-color: hsl(240, 60%, 55%);
$cat-color: hsl(280, 35%, 30%);
$fox-color: hsl(5, 60%, 50%);
$bear-color: hsl(20, 30%, 35%);

.#{$base-class} {
  height: 75vmin;
  margin: $block-space auto;
  padding: 5vmin;
  position: relative;
  width: 75vmin;

  &-circle {
    border: 0.5vmin solid $line-color;
    border-radius: 50%;
    font-size: 4vmin;
    height: 100%;
    position: relative;
    transform: rotate(-500deg);
    transition: transform 500ms ease-in-out;
    width: 100%;

    &-node {
      align-items: center;
      border: 0.5vmin solid $line-color;
      border-radius: 50%;
      display: inline-flex;
      height: 10vmin;
      justify-content: center;
      overflow: hidden;
      position: absolute;
      width: 10vmin;

      &:focus {
        border: 0;
        box-shadow: 0 0 0 0.5vmin $line-color;

        &::before {
          border-bottom-width: 1.3vmin;
          border-top-width: 1.3vmin;
          height: 1.3vmin;
          margin-top: -1.95vmin;
          width: 7.8vmin;
        }

        &::after {
          border: 4vmin solid transparent;
          margin-top: -5.85vmin;
        }
      }

      &::before {
        border-bottom: 1.2vmin solid transparent;
        border-top: 1.2vmin solid transparent;
        content: '';
        height: 1.2vmin;
        margin-top: -1.8vmin;
        position: absolute;
        width: 7.2vmin;
      }

      &::after {
        border: 3.7vmin solid transparent;
        content: '';
        height: 0;
        margin-top: -5.4vmin;
        position: absolute;
        width: 0;
      }

      &[data-id='horse'] {
        background-color: $horse-color;
        left: calc(50% - 5vmin + 22.5vmin);
        top: calc(50% - 5vmin - 22.5vmin);
        transform: rotate(45deg);

        &::before {
          border-top-color: $yang-color;
          background-color: $yang-color;
          border-bottom-color: $yin-color;
        }

        &::after {
          border-left-color: $horse-color;
          border-right-color: $horse-color;
        }
      }

      &[data-id='dove'] {
        background-color: $dove-color;
        left: calc(50% - 5vmin + 32vmin);
        top: calc(50% - 5vmin);
        transform: rotate(90deg);

        &::before {
          border-top-color: $yin-color;
          background-color: $yang-color;
          border-bottom-color: $yin-color;
        }

        &::after {
          border-left-color: $dove-color;
          border-right-color: $dove-color;
        }
      }

      &[data-id='turtle'] {
        background-color: $turtle-color;
        left: calc(50% - 5vmin + 22.5vmin);
        top: calc(50% - 5vmin + 22.5vmin);
        transform: rotate(135deg);

        &::before {
          border-top-color: $yang-color;
          background-color: $yin-color;
          border-bottom-color: $yin-color;
        }

        &::after {
          border-left-color: $turtle-color;
          border-right-color: $turtle-color;
        }
      }

      &[data-id='owl'] {
        background-color: $owl-color;
        left: calc(50% - 5vmin);
        top: calc(50% - 5vmin + 32vmin);
        transform: rotate(180deg);

        &::before {
          border-top-color: $yin-color;
          background-color: $yin-color;
          border-bottom-color: $yin-color;
        }

        &::after {
          border-left-color: $owl-color;
          border-right-color: $owl-color;
        }
      }

      &[data-id='wolf'] {
        background-color: $wolf-color;
        left: calc(50% - 5vmin - 22.5vmin);
        top: calc(50% - 5vmin + 22.5vmin);
        transform: rotate(225deg);

        &::before {
          border-top-color: $yin-color;
          background-color: $yin-color;
          border-bottom-color: $yang-color;
        }

        &::after {
          border-left-color: $wolf-color;
          border-right-color: $wolf-color;
        }
      }

      &[data-id='cat'] {
        background-color: $cat-color;
        left: calc(50% - 5vmin - 32vmin);
        top: calc(50% - 5vmin);
        transform: rotate(270deg);

        &::before {
          border-top-color: $yang-color;
          background-color: $yin-color;
          border-bottom-color: $yang-color;
        }

        &::after {
          border-left-color: $cat-color;
          border-right-color: $cat-color;
        }
      }

      &[data-id='fox'] {
        background-color: $fox-color;
        left: calc(50% - 5vmin - 22.5vmin);
        top: calc(50% - 5vmin - 22.5vmin);
        transform: rotate(315deg);

        &::before {
          border-top-color: $yin-color;
          background-color: $yang-color;
          border-bottom-color: $yang-color;
        }

        &::after {
          border-left-color: $fox-color;
          border-right-color: $fox-color;
        }
      }

      &[data-id='bear'] {
        background-color: $bear-color;
        left: calc(50% - 5vmin);
        top: calc(50% - 5vmin - 32vmin);
        transform: rotate(360deg);

        &::before {
          border-top-color: $yang-color;
          background-color: $yang-color;
          border-bottom-color: $yang-color;
        }

        &::after {
          border-left-color: $bear-color;
          border-right-color: $bear-color;
        }
      }
    }
  }

  &[data-selected='horse'] .#{$base-class}-circle {
    transform: rotate(-45deg);
  }

  &[data-selected='dove'] .#{$base-class}-circle {
    transform: rotate(-90deg);
  }

  &[data-selected='turtle'] .#{$base-class}-circle {
    transform: rotate(-135deg);
  }

  &[data-selected='owl'] .#{$base-class}-circle {
    transform: rotate(-180deg);
  }

  &[data-selected='wolf'] .#{$base-class}-circle {
    transform: rotate(-225deg);
  }

  &[data-selected='cat'] .#{$base-class}-circle {
    transform: rotate(-270deg);
  }

  &[data-selected='fox'] .#{$base-class}-circle {
    transform: rotate(-315deg);
  }

  &[data-selected='bear'] .#{$base-class}-circle {
    transform: rotate(-360deg);
  }

  &-option {
    display: none;
    font-size: 4vmin;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    top: -50vmin;
    width: 50vmin;

    &-heading {
      font-size: 6vmin;
      font-weight: bold;
      margin: 0 auto 1vmin;
      width: 75%;
    }

    &-list {
      list-style-type: none;
      margin: 0;
      padding: 0;

      &-item {
        margin: 0 0 1vmin;
      }
    }
  }

  &[data-selected='horse'] .#{$base-class}-option[data-id='horse'],
  &[data-selected='dove'] .#{$base-class}-option[data-id='dove'],
  &[data-selected='turtle'] .#{$base-class}-option[data-id='turtle'],
  &[data-selected='owl'] .#{$base-class}-option[data-id='owl'],
  &[data-selected='wolf'] .#{$base-class}-option[data-id='wolf'],
  &[data-selected='cat'] .#{$base-class}-option[data-id='cat'],
  &[data-selected='fox'] .#{$base-class}-option[data-id='fox'],
  &[data-selected='bear'] .#{$base-class}-option[data-id='bear'] {
    animation: $fade-in-animation;
    display: block;
    position: relative;
  }
}
