$medium-width: 500px;
$large-width: 800px;
$max-width: 1200px;

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/roboto-slab.ttf');
}
$slab-font: 'Roboto Slab', sans-serif;

@font-face {
  font-family: 'Righteous';
  src: url('./fonts/righteous.ttf');
}
$righteous-font: 'Righteous', sans-serif;

@font-face {
  font-family: 'Consolas';
  src: url('./fonts/consolas.ttf');
}
$consolas-font: 'Consolas', monospace;

@font-face {
  font-family: 'ASL-Font';
  src: url('./fonts/asl-font.ttf');
}
$asl-font: 'ASL-Font', sans-serif;

$normal-font: $slab-font;
$heading-font: $righteous-font;
$code-font: $consolas-font;
$normal-font-size: calc(1rem + 1vmin);
$normal-line-height: 1.25;
$small-font-size: 85%;
$small-line-height: 1.1;
$big-font-size: 115%;
$big-line-height: 0.9;
$heading-line-height: 1;

$bg-color: hsl(155, 50%, 15%);
$blue-bg-color: hsl(200, 50%, 28%);
$brown-bg-color: hsl(20, 40%, 30%);
$gray-bg-color: hsl(0, 0%, 30%);
$green-bg-color: hsl(145, 50%, 25%);
$purple-bg-color: hsl(290, 40%, 32%);
$red-bg-color: hsl(355, 50%, 32%);
$section-bg-color: hsla(290, 40%, 10%, 0.4);
$cell-bg-color: hsla(45, 100%, 80%, 0.8);
$cell-bg-hover-color: hsl(45, 100%, 80%);
$empty-cell-bg-color: hsla(45, 100%, 80%, 0.2);

$line-color: hsl(290, 40%, 10%);
$line-thickness: 3px;
$shadow-color: hsla(0, 0%, 0%, 0.5);
$shadow: 0 calc(0.1rem + 0.1vmin) calc(0.1rem + 0.2vmin) $shadow-color;
$focus-shadow: 0 calc(0.3rem + 0.3vmin) calc(0.3rem + 0.6vmin) $shadow-color;

$text-color: hsl(25, 50%, 95%);
$special-color: hsl(215, 90%, 80%);
$special-focus-color: hsl(215, 100%, 85%);
$link-color: hsl(45, 90%, 65%);
$link-focus-color: hsl(45, 100%, 80%);
$link-line-color: hsla(200, 50%, 28%, 0.6);
$link-line-focus-color: hsl(200, 50%, 28%);
$link-line-special-color: hsla(355, 50%, 32%, 0.6);
$link-line-special-focus-color: hsl(355, 50%, 32%);

$box-radius: calc(10px + 1vmin);
$box-padding: calc(5px + 1vw);
$small-box-padding: calc(2px + 0.5vw);
$cell-padding: calc(2px + 0.5vw);
$list-padding: 3em;
$section-space: calc(1rem + 4vmin);
$block-space: 0.6em;
$quote-space: 1.2em;
$icon-space: calc(0.4rem + 1vw);
$card-space: calc(0.4rem + 1vw);

$focus-time: 250ms;
$fade-time: 500ms;

@keyframes loadingFade {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}
$loading-animation: loadingFade $fade-time*2 infinite;

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
$fade-in-animation: fadeIn $fade-time;

@keyframes delayedFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
$delayed-fade-in-animation: delayedFadeIn $fade-time*2;

@keyframes delayedFadeHalfIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}
$delayed-fade-half-in-animation: delayedFadeHalfIn $fade-time*2;
