@import '../../tokens.scss';

.Card {
  align-items: center;
  background-color: $gray-bg-color;
  border-radius: $box-radius;
  box-shadow: $shadow;
  color: $text-color;
  display: flex;
  flex-direction: column;
  padding: $box-padding;
  transition: color $focus-time, box-shadow $focus-time;

  &:focus,
  &:hover {
    box-shadow: $focus-shadow;
  }

  &--blue {
    background-color: $blue-bg-color;
  }

  &--brown {
    background-color: $brown-bg-color;
  }

  &--green {
    background-color: $green-bg-color;
  }

  &--purple {
    background-color: $purple-bg-color;
  }

  &--red {
    background-color: $red-bg-color;
  }

  &-title {
    font-family: $heading-font;
    line-height: $heading-line-height;
    margin: 0 0 0.4em;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-text {
    font-size: $small-font-size;
    line-height: $small-line-height;
    text-align: center;
  }
}
