@import '../../tokens.scss';

.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: $large-width) {
    flex-direction: row;
    justify-content: space-between;
  }

  &-title {
    color: $special-color;
    font-family: $heading-font;
    font-weight: normal;
    line-height: 0.8;
    margin: 0;
    padding: $box-padding $box-padding 0;
    text-align: center;

    @media (min-width: $large-width) {
      text-align: left;
    }

    a {
      color: inherit;
      text-decoration: none;
      text-shadow: $shadow;
      transition: color $focus-time, text-shadow $focus-time;

      &:focus,
      &:hover {
        color: $link-focus-color;
        text-shadow: $focus-shadow;
      }

      &.active {
        &,
        &:focus,
        &:hover {
          color: inherit;
          text-shadow: $shadow;
        }
      }
    }
  }

  &-icons {
    display: flex;
    flex-wrap: wrap;
    gap: $icon-space;
    justify-content: center;
    padding: $box-padding $box-padding 0;

    @media (min-width: $large-width) {
      justify-content: right;
      padding-left: 0;
    }

    .Icon {
      box-shadow: $shadow;
      transition: color $focus-time, box-shadow $focus-time;

      &:focus,
      &:hover {
        box-shadow: $focus-shadow;
      }

      &.active {
        &,
        &:focus,
        &:hover {
          background-color: transparent;
          box-shadow: none;
          color: $special-color;
        }
      }
    }
  }
}
